
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  /* flex: 1 1 1; */
  align-items: center;
  position: relative;
  z-index: 2;
  padding-top: 2.8vw;
  padding-bottom: 2.8vw;
  padding-left: 4vw;
  padding-right: 4vw;
  user-select: none;
}

.header-logo {
  flex-grow: 2;
  flex-shrink: 0;
  width: 50vw;
  max-width: 50%;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 0;
}

#header-nav {
  max-width: 50%;
  z-index: 8;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 760px) {
  .header-logo {
    max-width: 85% !important;
  }

  .nav-button {
    max-width: 40%;
    position: relative !important;
    display: block !important;
    padding: 0.5rem !important;
    margin: auto !important;
    font-size: 3.5vh !important;
  }
  .nav-button-icon {
    padding: 1rem !important;
  }

}

#header-burger {
  z-index: 8;
  width: 5vw;
  padding: 2vw;
}

.nav-button {
  padding: 0.25vw;
  /* convert all the vw's to %'s */
  margin: 1vw;
  font-size: 1.6rem;
  transition: color 0.25s, text-decoration-color 0.25s, filter 0.25s ease-in, transform 0.5s ease;
  user-select: none;
  cursor: pointer;
}
.nav-button:hover {
  color: #ffd800;
  text-decoration-color: #ffd800;
  /* background-color: rgb(240, 240, 241); */
  filter: brightness(60%);
  transform: translateY(-2px);
}

.on {
  /* class for the button of the current page in nav menu */
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: #000;
}

.picture-button {
  max-height: 1.2rem;
  margin-top: 0.25vw;
  margin-bottom: 0.25vw;
}

.nav-button-icon {
  display: block;
  margin: auto;
  aspect-ratio: 1;
  transition: filter, transform 0.25s;
  /* filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.25)); */
}

.nav-button-icon:hover {
  /* filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.35)); */
  /* transform: scale(1.01) translateX(3px) translateY(-5px); */
  transform: scale(1.10) translateY(-2px);
}
#burger-menu {
  z-index: 6;
  position: absolute;
  /* top: 5vh; */
  left: 0px; 
  width: 100%;
  height: 85vh;
  display: block;
  padding-top: 10vh;
  background-color: rgba(255,255,255,0.9);
}

#burger-menu-flex {
  z-index: 6;
  display: flex;
  flex-direction: column;
}
