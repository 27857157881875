
.site-wrapper {
  /* width: 100%; */
  font-family: Garamond, serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5rem;
}

#contact-body {
  text-align: center;
}

img {
  display: block;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.reveal {
  opacity: 0;
}
.reveal-active {
  opacity: 1 !important;
  animation: fadein 1.75s 1;
}

svg {
  display: block;
}

.caption {
  text-align: center;
  white-space: pre-wrap;
  font-size: 1.3rem;
}
img.has-caption {
  margin-bottom: 0.75rem !important;
}

a > img:not([class*="button"]) {
  transition: filter, transform 0.4s;
  filter: drop-shadow(8px 8px 10px rgba(0, 0, 0, 0.25));
}

a > img:not([class*="button"]):hover {
  filter: drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.35)) sepia(40%);
  /* transform: scale(1.01) translateX(3px) translateY(-5px); */
  transform: scale(1.02) translateY(-5px) rotate(0.5deg);
}

.img-full {
  width: 100%;
}

.img-large {
  width: 65%;
}

.img-medium {
  width: 30%;
}

@media only screen and (max-width: 760px) {
  img {
    width: 85% !important;
  }
  .img-large, .img-medium {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .flex-wide {
    flex-direction: column !important;
  }
}

.section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.flex-wide {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.flex-tall {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.flex-equal-content {
  flex: 1 1;
}

.flex-tall .img-medium {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.flex-wide .flex-text {
  flex-basis: 50%;
  font-size: 1.6rem;
}

/* @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

.alfa {
  font-family: 'Alfa Slab One', cursive;
  display: inline-block;
  -webkit-transform: scale(1.3,1);
  -moz-transform: scale(1.3,1);
  -ms-transform: scale(1.3,1);
  -o-transform: scale(1.3,1);
  transform: scale(1.3,1);
} */
#tour-items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: azure;
}

.tour-item:first-of-type {
  margin-top: 4rem;
}

.ticket-button {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.ticket-button:hover,
.ticket-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.ticket-button:hover {
  transform: translateY(-1px);
}

.ticket-button:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}


.parallax {
  perspective: 1px;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.parallax__layer {
  position: absolute;
  top: 0;
}
.parallax__layer--base {
  z-index: 1;
  transform: translateZ(5);
}
.parallax__layer--back {
  transform: translateZ(-2px) scale(3);
  /*The scale factor can be calculated with 1 + (translateZ * -1) / perspective. For example,
    if our viewport perspective is set to 1px and we translate an element -2px along the Z axis
    the correction scale factor would be 3: like transform: translateZ(-2px) scale(3); */
}

.tour-parallax-container {
  background-color: rgb(73, 68, 60);
}

#tour-background {
  z-index: -1;
  width: max-content;
  filter: sepia(42%) brightness(27%) contrast(70%);
  -webkit-filter: sepia(42%) brightness(27%) contrast(70%);
  -moz-filter: sepia(42%) brightness(27%) contrast(70%);
}